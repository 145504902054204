<template>
  <div>
    <a-modal
      v-model="visible"
      :title="action + ' pengguna'"
      :dialogStyle="{ top: '10px' }"
      :destroyOnClose="true"
    >
      <template slot="footer">
        <a-button class="btn btn-outline-dark" @click="handleCancel">
          Cancel
        </a-button>
        <a-button
          class="btn btn-outline-primary"
          :loading="loading"
          @click="submit('ruleForm')"
        >
          Submit
        </a-button>
      </template>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-form-model-item
              style="margin: unset;"
              ref="name"
              label="Name"
              prop="name"
            >
              <a-input
                v-model="form.name"
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <!-- <a-form-model-item style="margin: unset;" ref="username" label="Username" prop="username">
              <a-input
                v-model="form.username"
                @blur="
                  () => {
                    $refs.username.onFieldBlur();
                  }
                "
              />
            </a-form-model-item> -->
            <a-form-model-item
              style="margin: unset;"
              ref="email"
              label="Email"
              prop="email"
            >
              <a-input
                autocomplete="off"
                v-model="form.email"
                @blur="
                  () => {
                    $refs.email.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              has-feedback
              label="Role"
              prop="id_role"
            >
              <a-select v-model="form.id_role">
                <a-select-option
                  v-for="data in datarole"
                  :key="data.id"
                  :value="data.id"
                  >{{ data.title }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              v-if="action === 'Create'"
              has-feedback
              label="New Password"
              prop="password"
            >
              <a-input-password v-model="form.password" autocomplete="off" />
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              v-if="action === 'Create'"
              has-feedback
              label="Confirm Password"
              prop="password_confirmation"
            >
              <a-input-password
                v-model="form.password_confirmation"
                autocomplete="off"
              />
            </a-form-model-item>
            <!-- <a-form-model-item
              style="margin: unset;"
              has-feedback
              label="Status"
              prop="status"
            >
              <a-switch v-model="form.status">
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
              </a-switch>
            </a-form-model-item> -->
            <a-form-model-item
              style="margin: unset;"
              has-feedback
              label="Allow Delete"
              prop="allow_Delete"
            >
              <a-switch v-model="form.allow_Delete">
                <a-icon slot="checkedChildren" type="check" />
                <a-icon slot="unCheckedChildren" type="close" />
              </a-switch>
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="selectUserApp"
              label="User App"
              prop="selectUserApp"
            >
              <a-select v-model="form.selectUserApp" mode="multiple" @change="userAppSelect">
                <a-select-option
                  v-for="data in dataapplist"
                  :key="data.id"
                  :value="data.id"
                  >{{ data.appName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="id_Default_App"
              label="Default App"
              prop="id_Default_App"
            >
              <a-select v-model="form.id_Default_App">
                <a-select-option
                  v-for="data in dataapplist"
                  :key="data.id"
                  :value="data.id"
                  >{{ data.appName }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="user_type"
              label="User Type"
              prop="user_type"
            >
              <a-select v-model="form.user_type">
                <a-select-option
                  value="User"
                  >User</a-select-option
                >
                <a-select-option
                  value="Mobile"
                  >Mobile</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              v-if="form.user_type === 'Mobile'"
              style="margin: unset;"
              ref="mode"
              label="Mode"
              prop="mode"
            >
              <a-select v-model="form.mode">
                <a-select-option
                  :value="1"
                  >Online</a-select-option
                >
                <a-select-option
                  :value="0"
                  >Offline</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="id_branch"
              label="Branch"
              prop="id_branch"
            >
              <a-select v-model="form.id_branch" @change="checkvalue">
                <a-select-option
                  v-for="data in databranchlist"
                  :key="data.id"
                  :value="data.id"
                  >{{ data.kode + ' - ' + data.keterangan }}</a-select-option
                >
              </a-select>
            </a-form-model-item>
            <a-form-model-item
              style="margin: unset;"
              ref="notes"
              label="Notes"
              prop="notes"
            >
              <a-textarea placeholder="Additional Notes" v-model="form.notes"/>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'

export default {
  name: 'editprofile',
  created() {
    // this.getMaster()
  },
  data() {
    const validatePass = (rule, value, callback) => {
      // if (this.action === 'Create') {
      //   if (this.form.password_confirmation !== '') {
      //     this.$refs.ruleForm.validateField('checkPass')
      //   }
      //   callback()
      // } else {
      if (value === '') {
        callback(new Error('Please input the password'))
      } else {
        if (this.form.password_confirmation !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
      // }
    }
    const validatePass2 = (rule, value, callback) => {
      // if (this.action === 'Create') {
      //   if (value !== this.form.password) {
      //     callback(new Error("Password doesn't match!"))
      //   } else {
      //     callback()
      //   }
      // } else {
      if (value === '') {
        callback(new Error('Please input the password again'))
      } else {
        if (value !== this.form.password) {
          callback(new Error("Password doesn't match!"))
        } else {
          callback()
        }
      }
      // }
    }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      user: [],
      datarole: [],
      dataapplist: [],
      databranchlist: [],
      roletags: false,
      editdata: {},
      action: 'Create',
      form: {
        name: '',
        email: '',
        // username: '',
        id_role: '',
        id_Default_App: '',
        user_type: 'User',
        mode: 1,
        id_branch: '',
        selectUserApp: [],
        user_App: [],
        // status: true,
        allow_Delete: true,
        password: '',
        password_confirmation: '',
        notes: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Please input name',
            trigger: 'blur',
          },
          {
            min: 5,
            max: 50,
            message: 'Length should be 5 to 50',
            trigger: 'blur',
          },
        ],
        username: [{ required: true, message: 'Please input username!' }],
        email: [
          {
            required: true,
            type: 'email',
            message: 'This is Not valid E-mail!',
          },
        ],
        password: [{ validator: validatePass, trigger: 'change' }],
        password_confirmation: [
          { validator: validatePass2, trigger: 'change' },
        ],
      },
      loading: false,
      visible: false,
      datenow: null,
    }
  },
  methods: {
    // async getMaster() {
    //   var resp = await lou.readMaster('id_role')
    //   this.datarole = resp.data
    // },
    checkvalue() {
      console.log('this.form.id_branch', this.form.id_branch)
    },
    userAppSelect(e) {
      // console.log('e', e)
      var temp = []
      for (let m = 0; m < e.length; m++) {
        const element = e[m]
        temp.push({ id_app: element })
      }
      // console.log('temp', temp)
      this.form.user_App = temp
    },
    async showModal(action, data = {}) {
      // console.log('data', data)
      this.editdata = data
      var res = await lou.readMaster('roles', false, true)
      var resapplist = await lou.readMaster('users/applist', false, true)
      var resbranches = await lou.readMaster('branches', false, true)
      // console.log('resapplist.data', resapplist.data)
      this.datarole = res.data
      this.dataapplist = resapplist.data
      this.databranchlist = resbranches.data
      if (action === 'Update') {
        console.log('data', data)
        this.form.id = data.id
        this.form.name = data.name
        this.form.email = data.email
        this.form.username = data.username
        this.form.id_role = data.id_Role
        this.form.id_Default_App = data.id_Default_App
        this.form.user_type = data.user_type
        this.form.mode = data.mode
        this.form.id_branch = data.id_branch !== null ? parseInt(data.id_branch) : ''
        var datauserapp = []
        if (data.appLists.length !== 0) {
          for (let i = 0; i < data.appLists.length; i++) {
            const el = data.appLists[i]
            datauserapp.push({ id_app: el.id })
          }
        }
        this.form.user_App = datauserapp
        var temp = []
        for (let h = 0; h < data.appLists.length; h++) {
          const el = data.appLists[h]
          temp.push(el.id)
        }
        this.form.selectUserApp = temp
        // this.form.status = data.status === 1
        this.form.allow_Delete = data.allow_Delete === 1
        this.form.notes = data.notes
        // this.form.password_confirmation = data.password
      } else {
        this.form.name = ''
        this.form.email = ''
        this.form.username = ''
        this.form.user_type = 'User'
        this.form.mode = 1
        this.form.id_branch = this.databranchlist.length !== 0 ? this.databranchlist[0].id : ''
        this.form.id_role = this.datarole.length !== 0 ? this.datarole[0].name : ''
        this.form.id_Default_App = this.dataapplist.length !== 0 ? this.dataapplist[0].id : ''
        this.form.selectUserApp = []
        this.form.user_App = []
        this.form.notes = ''
        this.form.password = ''
        this.form.password_confirmation = ''
      }
      this.action = action
      this.visible = true
    },
    handleCancel() {
      this.visible = false
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          var fd = {
            name: this.form.name,
            // username: this.form.username,
            email: this.form.email,
            password: this.form.password,
            id_role: this.form.id_role,
            // status: this.form.status === true ? '1' : '0',
            status: '1',
            allow_Delete: this.form.allow_Delete === true ? 1 : 0,
            notes: this.form.notes,
            user_type: this.form.user_type,
            mode: this.form.mode,
            id_branch: this.form.id_branch,
            id_Default_App: this.form.id_Default_App,
            user_App: this.form.user_App,
          }
          if (this.action === 'Create') {
            await lou.createMaster('users', fd, true, true)
          } else {
            delete fd.password
            fd.id = this.form.id
            await lou.updateMaster('users', fd, true, true)
          }
          this.$parent.getData()
          this.visible = false
          this.resetForm(formName)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>

<style></style>
